<template>
  <div class="inside-bg">
    <div class="container" style="padding-top: 100px">
      <nav area-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item"><a href="/view-colleges">Colleges</a></li>
          <li class="breadcrumb-item active" aria-current="page" v-if="college">
            {{ college.college_name }}
          </li>
        </ol>
      </nav>
      <div class="col">
        <div class="row">
          <div class="col-lg-12 jobDetails">
            <div class="jobHeader" v-if="loading" style="height:200px;">
              <content-loader viewBox="0 0 476 50" primaryColor="#f3f3f3" secondaryColor="#cccccc" v-if="loading">
                <rect x="0" y="8" rx="3" ry="3" width="150" height="10" />
                <rect x="0" y="25" rx="3" ry="3" width="80" height="6" />
                <rect x="90" y="25" rx="3" ry="3" width="80" height="6" />
                <rect x="0" y="40" rx="3" ry="3" width="100" height="6" />
                <rect x="0" y="55" rx="3" ry="3" width="80" height="6" />
              </content-loader>
            </div>
            <div class="jobHeader" v-if="college && !loading">
              <h1 v-if="college.college_name">{{ college.college_name }}</h1>
              <h2 v-if="college.city_name">
                <span class="ml-0 jobIcon">
                  <img :src="$S3_url+'/Assets/images/location.svg'" alt="location" title="location" width="22"/>
                </span>
                {{ college.city_name }} &nbsp;&nbsp;
              </h2>
              <div class="float-right">
                <button class="btn btn-primary" @click="openEnquiryForm()">Enquiry</button>
              </div>

            </div>
            <div class="jobDescription" v-if="loading">
              <div style="height:110px;">
                <content-loader viewBox="0 0 476 124" primaryColor="#f3f3f3" secondaryColor="#cccccc" v-if="loading">
                  <rect x="0" y="5" rx="3" ry="3" width="100" height="6" />
                  <rect x="140" y="5" rx="3" ry="3" width="70" height="6" />
                  <rect x="260" y="5" rx="3" ry="3" width="70" height="6" />
                  <rect x="0" y="20" rx="3" ry="3" width="85" height="6" />
                  <rect x="0" y="35" rx="3" ry="3" width="80" height="6" />
                </content-loader>
              </div>
            </div>
            <div class="jobDescription  mb-12" v-if="college && !loading">
              <div class="row subDetails">
                <div class="col-md-12" v-if="college.courses && college.courses.length !== 0"><span>Courses: {{ getInstiCourses(college.courses)}}</span></div>
                <div class="col-md-12" v-if="college.courses && college.courses.length !== 0"><span>Branches: {{ getInstiBranches(college.branches)}}</span></div>
              </div>
            <div v-if="college.description">
                <hr />
                <div class="description" v-html="college.description"></div>
              </div>
            </div>

          
          </div>
        </div>
      </div>
     </div>

    <b-modal id='enquiry-form' title="Send your query" hide-footer>
      <form id="enquiry-form">
        <div>
          <v-textarea id="enquiry" v-model="req.enquiry" placeholder="Enter your query" cols="30" rows="5" outlined></v-textarea>
           <div class="error-feedback" v-if="(valid_err !== 0 && !req.enquiry)">
              {{ validate.response.enquiry.msg }}
            </div>
        </div>
        <div>
          <button class="btn btn-primary float-right" @click="submitEnquiry($event)">Send</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';
import Actions from '../libraries/apiActions.js';
import '../assets/hire.css';
import validation from '../libraries/validFormat'; 
export default {
    name:'Trainingcolleges',
    components: {
      ContentLoader
    },
    metaInfo: (vue) => {
      const seo_title = vue.seo_title;
      return {
        title: seo_title ? seo_title : '',
      };
    },
    data: function(){
      return{
        seo_title: '',
        college: [],
        college_courses:[],
        action: new Actions(),
        isLogin: false,
        loading: true,
        req: {
          enquiry: '',
          college_id: null,
        },
        valid_err: 0,
        validate: new validation(),
        courseDurationOptions: [
        { text: "1 week", value: 7 },
        { text: "2 weeks", value: 14 },
        { text: "3 weeks", value: 21 },
        { text: "1 month", value: 30 },
        { text: "2 months", value: 60 },
        { text: "3 months", value: 90 },
        { text: "4 months", value: 120 },
        { text: "5 months", value: 150 },
        { text: "6 months", value: 180 },
        { text: "7 months", value: 210 },
        { text: "8 months", value: 240 },
        { text: "9 months", value: 270 },
        { text: "10 months", value: 300 },
        { text: "11 months", value: 330 },
        { text: "12 months", value: 360 },
        { text: "1 year", value: 365 },
        { text: "2 years", value: 730 },
        { text: "3 years", value: 1095 },
        { text: "4 years", value: 1460 },
        { text: "5 years", value: 1825 },
      ],
      }
    },
    beforeMount(){
      this.getCollege();
      this.isLogin = (this.$cookies.get("hire-seeker")&&this.$cookies.get("hire-seeker").token) ? true:false;
    },
    methods: {
      showCourseDetails: function(id){
        window.open(`/training-courses/${id}`,'_blank');
      },
      resetForm: function(){
        this.req.enquiry = '';
        this.req.course_id = [];
        this.req.interested = 0;
      },
      openEnquiryForm: function(){
        if(this.isLogin){
          this.resetForm();
          this.$bvModal.show('enquiry-form');
        } else{
          this.redirect_from = this.$route.path;
          let url = '/jobseeker/register?src='+this.college.college_name+'&redirect_from='+this.redirect_from;
          window.open(url, "_blank").focus();
        }
        console.log('he;0')
      },
      submitEnquiry: function(e){
        let token = this.$cookies.get("hire-seeker").token;
        this.valid_err = 0;
        e.preventDefault();
        // if (this.validate.checkIfEmpty(this.req.enquiry, "enquiry","Plese enter a query")?.val === false) {
        //   this.valid_err++;
        // }
        // if (this.validate.checkIfEmpty(this.req.course_id, "course_id","Plese choose a course")?.val === false) {
        //   this.valid_err++;
        // }
        // if(this.valid_err == 0){
          this.action.submitCollegeLead(this.req, token).then(res => {
          if(res.status){
           this.$bvModal.hide('enquiry-form');
           this.showMsgModal("Enquiry submitted");
          }
          }).catch(err =>{
            console.log(err);
          })
        // }
      },
      showMsgModal(msg = null) {
      this.boxOne = '';
      this.$bvModal.msgBoxOk(msg, {
          buttonSize: 'sm',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
      });
    },
      getCollege: function(){
        let param_split = this.$route.params.id.split('-')
        let id = param_split[param_split.length - 1];
        
        this.action.getCollegeDetails(id).then(res => {
          if(res.status){
            this.loading = false;
            this.college = res.college;
            this.seo_title = this.college.college_name;
            this.req.college_id = this.college.college_id;
          }
        }).catch(err =>{
          console.log(err);
        })
      },
      getInstiCourses: function(courses){
        return courses.map(course => course.course_name).join(', ');
      },
      getInstiBranches: function(branches){
        return branches.map(branch => branch.branch_name).join(', ');
      },
      getClassMode(mode){
        switch(mode) {
            case 0: return 'Classroom'; 
            case 1: return 'Online'; 
            case 2: return 'Both'; 
            default: break;
        }
      },
      getCourseDurationText(value) {
        const durationOption = this.courseDurationOptions.find(option => option.value === value);
        return durationOption ? durationOption.text : 'Unknown';
      },
    }
}
</script>
<style scoped>
/* .breadcrumb {
    background-color: transparent;
    margin-bottom: 0;
  } */
 #course_options{
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
 }
 .breadcrumb {
    background-color: transparent;
    margin-bottom: 0;
  }
  h1 {
    font-size: 22px;
    font-weight: 700;
  }
  .v-text-field__details{display: none !important;}
  .v-input__control fieldset {
    background-color: #fff; /* Your desired background color */
  }
  @media only screen and (max-width: 640px) {
    h1{
      font-size: 15px;
    }
 }
</style>